export default {
    api: {
        path: process.env.VUE_APP_API_PATH || '',
    },
    app: {
        user: process.env.VUE_APP_USER || '',
        device: process.env.VUE_APP_DEVICE || '',
        startDate: process.env.VUE_APP_STARTDATE || '',
        map: {
            token: process.env.VUE_APP_MAP_TOKEN || '',
        }
    }
}