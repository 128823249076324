<template>
  <v-card elevation="2">
    <MapPreview 
      :latitude="this.locationInfo.location.latitude" 
      :longitude="this.locationInfo.location.longitude" />
    <v-card-title>{{ emojiFlag(locationInfo.location.country_code) }} {{ locationInfo.location.country_name }}<span v-if="locationInfo.location.locality">, {{ locationInfo.location.locality }}</span></v-card-title>
    <v-divider />
    <v-card-text>
      <p>
        ⌚ {{ timestamp() }}
      </p>
      <p>
        🌡️ {{ locationInfo.weather.temp }}°C <span style="font-size:large">{{ weatherIcon(locationInfo.weather.icon) }}</span>
      </p>
      <p>
        🎏 {{ locationInfo.weather.wind_speed_ms }} m/s <span v-if="locationInfo.weather.wind_speed_ms >= 8">⚠️</span>
      </p>
      <p v-if="locationInfo.location.address">
        📍 {{ locationInfo.location.address }}
      </p>
    </v-card-text>
    <v-card-text>
      <v-row align="center" justify="space-around">
        <v-btn
          text
          color="primary"
          :href="`https://www.google.com/maps/search/?api=1&query=${locationInfo.location.latitude}%2C${locationInfo.location.longitude}`"
          target="_blank"
          >Google Maps</v-btn>

        <v-spacer />
        
        <v-btn
          text
          color="primary"
          href="/map"
          target="_blank"
        >Zur Live Karte</v-btn>
      </v-row>
    </v-card-text>
    
  </v-card>
</template>

<script>
import EmojiFlag from '../mixins/EmojiFlag.vue'
import MapPreview from './MapPreview.vue';
import dayjs from 'dayjs';

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)

export default {
    name: "CurrentLocation",
    mixins: [EmojiFlag],
    props: {
        locationInfo: Object,
    },
    methods: {
        timestamp() {
            return dayjs(this.locationInfo.location.timestamp).tz("Europe/Berlin").format("D.M.YYYY HH:mm");
        },
        weatherIcon(owm) {
            const owmIconClass = owm.toString().slice(0, -1);
            switch (owmIconClass) {
                case "01": // clear sky
                    return "☀️";
                case "02": // few clouds
                    return "🌤️";
                case "03": // scattered clouds
                    return "⛅";
                case "04": // broken clouds
                    return "☁️";
                case "09": // shower rain
                    return "🌧️";
                case "10": // rain
                    return "🌦️";
                case "11": // thunderstorm
                    return "🌩️";
                case "13": // snow
                    return "🌨️";
                case "50": // mist
                    return "🌫️";
                default:
                    return "";
            }
        }
    },
    components: { MapPreview }
}
</script>
