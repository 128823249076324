<template>
  <div id="mapPreviewWrapper">
    <v-img
      :src="staticTileUrl()"
      :height="this.mapPreviewHeight + 'px'"
    >
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular
            indeterminate
            color="blue lighten-2"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'MapPreview',
  props: {
    latitude: Number,
    longitude: Number,
  },
  data() {
    return {
      mounted: false,
      mapPreview: {
        style: "streets-v11",
        height: 300,
        width: 650,
        zoom: 10,
        token: this.$config.app.map.token,
        marker: "pin-l-bus+f74e4e",
      },
    }
  },
  computed: {
    mapPreviewWidth() {
      if (!this.mounted) return
      return this.mapPreview.width
    },
    mapPreviewHeight() {
      return this.mapPreview.height
    },
    resolutionTag(){
      if (((window.matchMedia && (window.matchMedia('only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx), only screen and (min-resolution: 75.6dpcm)').matches || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2)').matches)) || (window.devicePixelRatio && window.devicePixelRatio >= 2)) && /(iPad|iPhone|iPod)/g.test(navigator.userAgent)) {
        return "@2x"
      }
      return ""
    }
  },
  mounted() {
    this.mounted = true
    this.getAndSetMapPreviewWidth()
    window.addEventListener('resize', _.debounce(this.getAndSetMapPreviewWidth, 100))
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getAndSetMapPreviewWidth)
  },
  methods: {
    getAndSetMapPreviewWidth() {
      this.mapPreview.width = document.getElementById('mapPreviewWrapper').offsetWidth
    },
    staticTileUrl() {
      if (this.latitude == 0.0 && this.longitude == 0.0) {
        return ""
      }
      return `https://api.mapbox.com/styles/v1/mapbox/${this.mapPreview.style}/static/${this.mapPreview.marker}(${this.longitude},${this.latitude})/${this.longitude},${this.latitude},${this.mapPreview.zoom}/${this.mapPreviewWidth}x${this.mapPreviewHeight}${this.resolutionTag}?access_token=${this.mapPreview.token}`
    },
  }
}
</script>
