<template>
  <v-card elevation="2">
    <v-card-title>📷 Fotos!</v-card-title>
    <v-row>
      <v-col class="d-flex child-flex" cols="4">
        <a href="https://mitene.us/f/i1b8lrG-_Q4" target="_blank">
        <v-img
          src="/photos/1.jpeg"
          aspect-ratio="1"
          class="grey lighten-2"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        </a>
      </v-col>

      <v-col class="d-flex child-flex" cols="4">
        <a href="https://mitene.us/f/i1b8lrG-_Q4" target="_blank">
        <v-img
          src="/photos/2.jpeg"
          aspect-ratio="1"
          class="grey lighten-2"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        </a>
      </v-col>

      <v-col class="d-flex child-flex" cols="4">
        <a href="https://mitene.us/f/i1b8lrG-_Q4" target="_blank">
        <v-img
          src="/photos/3.jpeg"
          aspect-ratio="1"
          class="grey lighten-2"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        </a>
      </v-col>
    </v-row>
    <v-row justify="space-around">
      <v-btn
          text
          color="primary"
          href="https://mitene.us/f/i1b8lrG-_Q4"
          target="_blank"
      >Zu den Fotos (bitte kein Passwort vergeben)</v-btn>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'PhotoBox',
}
</script>
