import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import config from "./config"
import { makeServer } from './server'

Vue.config.productionTip = false
Vue.prototype.$config = config

if (process.env.NODE_ENV === "development") {
  makeServer()
}

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
