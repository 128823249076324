<script>
  export default {
    methods: {
      emojiFlag(code) {
        const codePoints = code.toString()
          .toUpperCase()
          .split('')
          .map(char =>  127397 + char.charCodeAt());
        return String.fromCodePoint(...codePoints);
      },
    }
  }
</script>
