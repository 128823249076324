<template>
  <v-card elevation="2">
    <v-card-title>🗺️ Besuchte Länder</v-card-title>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th>Land</th>
            <th>Tage</th>
            <th>KM</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="country in countries" :key="country.country_name">
            <td>{{ emojiFlag(country.country_code)}} {{country.country_name}}</td>
            <td>{{ country.days.toFixed(1) }}</td>
            <td>{{ country.distance_km.toFixed(2) }} km</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import EmojiFlag from "@/mixins/EmojiFlag.vue";
export default {
  name: 'CountryList',
  mixins: [EmojiFlag],
  props: {
    countries: Array,
  },
}
</script>
