<template>
  <v-container max-width="1185">
    <v-app>
        <v-app-bar app>
            <v-spacer />
            <v-app-bar-title>🚐 Wo sind die Richters?</v-app-bar-title>
            <v-spacer />
        </v-app-bar>

        <v-main >
          <v-row>
            <v-col sm="6" md="6">
              <v-row>
                <v-col>
                  <CurrentLocation v-bind:locationInfo="data.last_location_info" />
                </v-col>
              </v-row>
            </v-col>
            <v-col sm="6" md="6">
              <v-row>
                <v-col>
                  <PhotoBox />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <CountryList v-bind:countries="data.countries" />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12" md="12">
              <AchievementList v-bind:distance_travelled_km="data.distance_travelled_km"
                                v-bind:distance_today_km="data.distance_today_km"
                                v-bind:greatest_distance_km="data.greatest_distance_km"
                                v-bind:days_in_countries="data.days_in_countries"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12" md="12">
              <OvernightStays v-bind:overnightStays="data.overnight_stays" />
            </v-col>
          </v-row>
        </v-main>
    </v-app>
  </v-container>
</template>

<script>
import CurrentLocation from './components/CurrentLocation'
import AchievementList from './components/AchievementList'
import CountryList from './components/CountryList'
import OvernightStays from './components/OvernightStays'
import PhotoBox from './components/PhotoBox';

export default {
  name: 'App',
  
  components: {
    CurrentLocation,
    AchievementList,
    CountryList,
    OvernightStays,
    PhotoBox
},

  data: () => ({
    data: {
      last_location_info: {
        location: {
          address: "",
          country_name: "",
          country_code: "DE",
          locality: "",
          timestamp: "2022-07-24T05:00:00Z",
          latitude: 0.0,
          longitude: 0.0,
        },
        weather: {
          unit: "",
          temp: 0.0,
          temp_feels_like: 0.0,
          icon: "",
          wind_speed_ms: 0.0,
          wind_direction_deg: 0.0,
        },
      },
      distance_travelled_km: 0.0,
      greatest_distance_km: {
        date: "2022-07-24T05:00:00Z",
        distance_km: 0.0
      },
      distance_today_km: {
        date: "",
        distance_km: 0.0,
      },
      countries: [],
      overnight_stays: [],
    }
  }),

  mounted: function() {
    var vm = this;
    fetch(this.$config.api.path)
      .then(r => r.json())
      .then(d => {
        vm.data.last_location_info = d.last_location_info
        vm.data.greatest_distance_km = d.greatest_distance_km
        vm.data.distance_travelled_km = d.distance_travelled_km
        vm.data.distance_today_km = d.distance_today_km
        vm.data.countries = d.countries
        vm.data.overnight_stays = d.overnight_stays.reverse()
      })
      .catch(err => {
        console.log(err)
      })
  }
};
</script>
